import axios from 'axios';
import { getStorage, ref, uploadBytes, deleteObject, getDownloadURL } from 'firebase/storage';
import { v4 as generateUUID } from 'uuid';

import { firebaseApp } from '../auth/context/firebase/auth-provider';

class CloudStorage {
  storage = getStorage(firebaseApp);

  bucket = 'app';

  async upload(id: string, type: string, file: File | Buffer) {
    const filename = generateUUID();
    const path = `${this.bucket}/${type}/${id}/${filename}`;
    const storageRef = ref(this.storage, path);
    let url;
    try {
      await uploadBytes(storageRef, file);
      url = await getDownloadURL(storageRef);

      // Detect if image contains a face and is safe
      const isSafe = await CloudStorage.checkImageSafety(url);

      if (!isSafe) {
        await this.delete(storageRef.fullPath);
        throw new Error('Image is not safe.');
      }
    } catch (e) {
      console.error(e);
      return null;
    }
    return { bucketPath: storageRef.fullPath, url };
  }

  // Static method using API key for Google Vision API requests
  static async checkImageSafety(imageUrl: string): Promise<boolean> {
    const visionApiUrl = `https://vision.googleapis.com/v1/images:annotate?key=AIzaSyB1tPQwZ69-KClWYJnko8giKN-EIfV-hRY`;

    const requestPayload = {
      requests: [
        {
          image: {
            source: {
              imageUri: imageUrl,
            },
          },
          features: [
            {
              type: 'SAFE_SEARCH_DETECTION',
            },
            {
              type: 'FACE_DETECTION',
            },
          ],
        },
      ],
    };

    try {
      const response = await axios.post(visionApiUrl, requestPayload);
      const detections = response.data.responses[0].safeSearchAnnotation;
      const faces = response.data.responses[0].faceAnnotations;

      const isSafeContent =
        (detections.adult === 'VERY_UNLIKELY' || detections.adult === 'UNLIKELY') &&
        (detections.violence === 'VERY_UNLIKELY' || detections.violence === 'UNLIKELY') &&
        (detections.racy === 'VERY_UNLIKELY' ||
          detections.racy === 'UNLIKELY' ||
          detections.racy === 'POSSIBLE' ||
          detections.racy === 'LIKELY');

      return isSafeContent && faces !== undefined && faces.length > 0;
    } catch (error) {
      console.error('Error detecting image safety:', error);
      return false;
    }
  }

  async delete(bucketPath: string) {
    const storageRef = ref(this.storage, bucketPath);

    try {
      await deleteObject(storageRef);
    } catch (e) {
      console.error(e);
    }
  }
}

export const cloudStorageService = new CloudStorage();
